import React from 'react'
import './Footer.css'
import Github from '../../assets/github.png'
import Instagram from '../../assets/instagram.png'
import Linkeldn from '../../assets/linkedin.png'
import Logo from '../../assets/logo.png'
const Footer = () => {
  return (
    <div className='Footer-Container'>
      <hr/>
      <div className='footer'>
        <div className='social-links'>
        <img src={Github} alt=""></img>
        <img src={Instagram} alt=""></img>
        <img src={Linkeldn}  alt=""></img>
        </div>
        <div className='logo-f'>
        <img src={Logo} alt=""></img>
      </div>

      </div>
      <div className='blur footer-blur-1'></div>
      <div className='blur footer-blur-2'></div>
    </div>
  )
}

export default Footer
